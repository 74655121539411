import { currencyFormatter, Vehicle } from "@alba-cars/common-modules";
import { StaticImageData } from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { KeyboardEvent } from "react";

import { getCarMonthlyInstallment, getCarPrice, slugify } from "@/lib/utils";
import defaultVehicleImage from "@/public/assets/images/tesla.jpg";
import { DEFAULT_PLACEHOLDER } from "@/utils/constants";
import { getCarNameTitle } from "@/utils/utils";
import Slider from "../common/slider/Slider";
import IconArrowRight from "../Icons/IconArrowRight";
import CustomButton from "./buttons/Button";
import { CarFavoriteButton } from "./buttons/CarFavoriteButton";
import { CarCardImage } from "./CarCardImage";

interface CarsCardProps {
  car: Vehicle;
  favoriteButtonCallback?: () => void;
  showBuyThisCar?: boolean;
  hideFavoriteButton?: boolean;
  useSlider?: boolean;
}

export default function CarsCard({
  car,
  favoriteButtonCallback,
  showBuyThisCar = false,
  hideFavoriteButton = false,
  useSlider = true,
}: CarsCardProps) {
  const router = useRouter();

  if (!car) {
    return null;
  }

  // Safe getters for potentially null values
  const mediaUrls: { mediaUrl?: string | StaticImageData; placeholder?: string }[] =
    car?.media
      ?.filter((media) => !!media.mediaUrl)
      .map((media) => ({
        mediaUrl: media.mediaUrl,
        placeholder: media.placeholder,
      })) || [];

  !mediaUrls.length &&
    mediaUrls.push({
      mediaUrl: defaultVehicleImage,
      placeholder: DEFAULT_PLACEHOLDER,
    });

  // Only render if we have a valid car object

  const refId = car?.refId || "";
  const trim = car?.trim;
  const makeName = car?.make?.name || "";
  const modelName = car?.model?.name || "";
  const makeModel = getCarNameTitle(car, { showTrim: true });
  const mileage = car?.mileage || 0;
  const year = car?.year || "";
  const price = getCarPrice(car);
  const installment = getCarMonthlyInstallment(car);
  const status = car?.status || "";

  const detailsLink = trim
    ? `${refId}-${slugify(makeName)}-${slugify(modelName)}-${slugify(trim)}`
    : `${refId}-${slugify(makeName)}-${slugify(modelName)}`;

  const handleBuyCar = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const params = new URLSearchParams();
    params.set("deposit", "5000");
    router.push(`/book_car/${detailsLink}?${params.toString()}`);
  };
  const cardUrl = `/buy-used-cars/vehicle/${detailsLink}`;
  const handleCardClick = () => {
    router.push(cardUrl);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      handleCardClick();
    }
  };

  return (
    <div onClick={handleCardClick} role="button" tabIndex={0} onKeyDown={handleKeyDown}>
      <div className="hover:shadow-m group relative transform rounded-3xl bg-white p-1">
        <div className="relative">
          {/* Status Chip - Only displayed when status is "Reserved" */}
          {status === "Reserved" && (
            <div className="inter absolute left-3 top-3 z-50 rounded-lg bg-primary px-3 py-1 text-center text-xs font-semibold text-white">
              Reserved
            </div>
          )}

          {useSlider ? (
            <>
              <Slider
                navigationEnabled={true}
                paginationEnabled={false}
                navigation={{
                  nextEl: `.custom-swiper-button-next-${car.id}`,
                  prevEl: `.custom-swiper-button-prev-${car.id}`,
                }}
                loop={true}
                virtual={{
                  addSlidesAfter: 1,
                  addSlidesBefore: 1,
                  enabled: true,
                }}
              >
                {mediaUrls.map((media, index) => (
                  <CarCardImage
                    key={`${index}-${media.mediaUrl}`}
                    mediaUrl={media.mediaUrl}
                    blurPlaceholder={DEFAULT_PLACEHOLDER}
                    makeModel={makeModel}
                    index={index}
                  />
                ))}
              </Slider>
              <div
                className={`custom-swiper-button-prev-${car.id} absolute bottom-11 right-3 z-50 hidden h-8 w-8 -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-full bg-white opacity-0 transition-opacity duration-300 ease-in-out md:group-hover:flex md:group-hover:opacity-100`}
              >
                <button aria-label="Show previous image">
                  <IconArrowRight
                    fill="hsla(var(--secondary-foreground))"
                    width="15"
                    height="15"
                    className="-rotate-180"
                  />
                </button>
              </div>

              <div
                className={`custom-swiper-button-next-${car.id} absolute bottom-0 right-3 z-50 hidden h-8 w-8 -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-full bg-white opacity-0 transition-opacity duration-300 ease-in-out md:group-hover:flex md:group-hover:opacity-100`}
              >
                <button aria-label="Show next image">
                  <IconArrowRight fill="hsla(var(--secondary-foreground))" width="15" height="15" />
                </button>
              </div>
            </>
          ) : (
            <CarCardImage
              index={0}
              mediaUrl={car?.media?.[0]?.mediaUrl}
              blurPlaceholder={DEFAULT_PLACEHOLDER}
              makeModel={makeModel}
            />
          )}
        </div>

        {!hideFavoriteButton && <CarFavoriteButton carId={car.id} unfavoriteCallback={favoriteButtonCallback} />}

        <div className="relative p-4">
          <div className="inter line-clamp-2 flex h-[3rem] max-h-[3rem] items-center justify-between text-base">
            <Link
              href={cardUrl}
              onClick={(e) => e.stopPropagation()}
              className="flex w-full items-center justify-between"
            >
              <h3 className="max-w-54 text-wrap font-semibold md:group-hover:text-primary">{makeModel}</h3>
              <span className="font-bold">{year}</span>
            </Link>
          </div>

          <div className="inter my-5 flex justify-between border-b-2 border-[#E9E8E8] pb-5">
            <div className="group flex flex-col justify-start space-y-1">
              <span className="text-xs font-medium text-[#898989]">Starts from</span>
              <span className="text-sm font-bold text-primary">
                {currencyFormatter({ isDecimal: true, decimalPlaces: 0 }).format(installment)}
                /month
              </span>
            </div>

            <div className="group flex flex-col justify-end space-y-1">
              <span className="text-xs font-medium text-[#898989]">Full Price</span>
              <span className="text-sm font-bold text-black">
                {currencyFormatter({ isDecimal: true, decimalPlaces: 0 }).format(price)}
              </span>
            </div>
          </div>

          <div className="inter group flex items-center justify-between">
            <span className="text-xs font-medium text-[#898989]">Odometer</span>
            <span className="text-sm font-medium text-black">{mileage.toLocaleString()} kms</span>
          </div>
          {showBuyThisCar && (
            <CustomButton
              buttonText="Buy this car"
              className="mt-4 w-full rounded-2xl bg-primary py-3 font-inter text-white transition-all duration-300 ease-in-out hover:bg-primary-dark"
              onClick={handleBuyCar}
            />
          )}
        </div>
      </div>
    </div>
  );
}
